<ng-container *ngIf="model != null">
    <div class="wrapper px-1">
        <!-- Task info: Cancelada -->
        <ng-container *ngIf="isTaskCancelled; else componentTemplate">
            <div class="message-container">
                <b>
                    Tarefa cancelada automaticamente pelo sistema em
                    <span class="d-inline-block text-highlight relevant-info-font">{{dateString}}</span>
                </b>
            </div>

            <hr />

            <div class="message-container">
                <p>
                    O Fluxo
                    <span class="d-inline-block text-highlight relevant-info-font">{{inputFlowInstance.name}}</span>
                    foi configurado para que qualquer instância do mesmo seja cancelada automaticamente
                    após data e hora específicos ou em caso de inatividade prolongada.
                </p>
                <p>
                    Para todos os efeitos, o Fluxo <b>não foi completado</b> e as eventuais Tarefas previamente executadas <b>não possuem validade</b>.
                </p>
                <span>
                    Inicie uma <b>nova instância</b> deste Fluxo para completar a requisição do serviço.
                </span>
            </div>
        </ng-container>

        <ng-template #componentTemplate>
            <!-- Seletor de papel -->
            <ng-container *ngIf="isTaskPublicAgentActor && hasPendingTask && papeisSelector.length > 0">
                <papel-selector [class.mandatory]="forgotPapel && Utils.isNullOrEmpty(inputData.eDocsData.signerId)"
                                [inputIsReadOnly]="isReadOnly"
                                [inputPapeis]="papeisSelector"
                                [inputFlowObjectType]="FlowObjectType.TaskFinishContent"
                                [(signerId)]="inputData.eDocsData.signerId"
                ></papel-selector>
                <hr />
            </ng-container>

            <!-- Task info: Realizada -->
            <div class="message-container" *ngIf="!hasPendingTask; else pendingTaskTemplate">
                <ng-container *ngIf="isTextContentType; else attachmentsTemplate">
                    <div class="mb-3">
                        O seguinte conteúdo foi fornecido pelo setor responsável ao executar esta Tarefa de Encerramento:
                    </div>
                    <div>
                        <i class="document-highlight relevant-info-font">{{inputData.message}}</i>
                    </div>

                    <hr />

                    <div class="mb-3">
                        Documento comprobatório da realização desta Tarefa:
                    </div>
                </ng-container>

                <ng-template #attachmentsTemplate>
                    <div class="mb-3">
                        <span *ngIf="!isPlural">O seguinte documento anexo foi fornecido</span>
                        <span *ngIf="isPlural">Os seguintes documentos anexos foram fornecidos</span>
                        <span> pelo setor responsável ao executar esta Tarefa de Encerramento:</span>
                    </div>
                </ng-template>

                <div class="documents-container">
                    <div class="position-relative my-1" *ngFor="let doc of currentDocuments">
                        <span class="document-highlight" (click)="openDocumentModal(doc.id)">
                            <span class="e-docs relevant-info-font text-highlight" matTooltip="Protocolo E-Docs do documento">{{doc.registro}}</span>
                            <span class="relevant-info-font d-inline-block" matTooltip="Clique para visualizar o documento que foi capturado no E-Docs">
                                <i class="fas fa-external-link-alt mr-2"></i><u>{{doc.filename}}</u>
                            </span>
                        </span>
                    </div>
                </div>

                <hr />

                <!-- Mensagem pública customizada -->
                <div class="public-message py-1" [class.loading]="publicMessageHtml == DEFAULT_LOADING_TEXT"
                     *ngIf="!Utils.isNullOrEmpty(flowObjectDefinition?.publicMessageHtml); else defaultMessageTemplate"
                     [innerHtml]="publicMessageHtml"
                ></div>

                <!-- Mensagem padrão -->
                <ng-template #defaultMessageTemplate>
                    <div><span>Fluxo <b>encerrado</b></span></div>
                </ng-template>
            </div>

            <!-- Task info: Iniciada -->
            <ng-template #pendingTaskTemplate>
                <!-- Task body: visão do requerente -->
                <div class="message-container" *ngIf="!isTaskPublicAgentActor; else pendingTaskPublicAgentActorTemplate">
                    <ng-container *ngIf="!Utils.isNullOrEmpty(configSchema?.taskFinishContent?.waitingMessage)">
                        <div class="public-message py-1" [class.loading]="waitingMessage == DEFAULT_LOADING_TEXT" [innerHtml]="waitingMessage"></div>
                    </ng-container>
                </div>

                <!-- Task footer: visão do executor -->
                <ng-template #pendingTaskPublicAgentActorTemplate>
                    <!-- Conteúdo de texto -->
                    <ng-container *ngIf="isTextContentType; else attachmentsTemplate">
                        <div class="form-container">
                            <span><span style="color: red">*</span> Mensagem do conteúdo que será capturado como resultado desta Tarefa:</span>

                            <div class="input-container text-center mt-1 px-0">
                                <textarea id="message" rows="4"
                                          [class.minlength]="isLackingMessage"
                                          [(ngModel)]="inputData.message"
                                ></textarea>
                            </div>

                            <div class="footer mt-3">
                                <button type="button" class="btn btn-outline-primary" (click)="submit()">Enviar</button>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Conteúdo de anexos -->
                    <ng-template #attachmentsTemplate>
                        <div class="mb-3">
                            <span>Forneça </span>
                            <span *ngIf="!isPlural">o documento anexo configurado</span>
                            <span *ngIf="isPlural">os documentos anexos configurados</span>
                            <span> para captura no E-Docs ao se executar esta Tarefa:</span>
                        </div>

                        <div class="attachments-container">
                            <div class="attachment-row" *ngFor="let item of configSchema.taskFinishContent.attachments">
                                <div class="attachment-info">
                                    <span class="title">{{item.label}}</span>
                                    <span class="label">Valor Legal</span>
                                    <span class="value">{{getDocumentLegalValueCombination(item)}}</span>
                                    <span class="label">Classificação Documental</span>
                                    <span class="value">{{item.documentClass.name}}</span>
                                    <span class="label">Nível de Acesso</span>
                                    <span class="value">{{item.accessLevel.name}}</span>
                                    <ng-container *ngIf="item.accessLevel.legalReasoningsNames?.length > 0">
                                        <span class="label">Fundamentação Legal</span>
                                        <span class="value">{{getLegalReasoningsNames(item)}}</span>
                                    </ng-container>
                                </div>

                                <pdf-upload (valueChange)="onPdfUploadChange($event.detail, item)"></pdf-upload>
                            </div>
                        </div>

                        <div class="footer mt-3">
                            <button type="button" class="btn btn-outline-primary" (click)="submit()">Enviar</button>
                        </div>
                    </ng-template>
                </ng-template>
            </ng-template>
        </ng-template>
    </div>
</ng-container>
