import { AccessLevelType, AccessLevelTypeDescription, DocumentLegalValueCombination } from "./edocs.model";

export class ConfigSchemaFlowDefinition {
    hasAutomaticFlowDefinitionDeactivation?: boolean = false;
    daysSinceFlowDefinitionActivationToAutoDeactivate?: number;
    dateTimeToAutoDeactivateFlowDefinition?: Date;

    hasAutomaticFlowInstanceCancellation?: boolean = false;
    daysSinceFlowInstanceStartToAutoCancel?: number;
    daysSinceFlowInstanceLastUpdateToAutoCancel?: number;
    dateTimeToAutoCancelFlowInstance?: Date;

    shouldAddInfoToEDocsTitles?: boolean = false;
    shouldNotAddInfoToDocuments?: boolean = false;
    addUserNameToEDocsTitles?: boolean = false;
    addUserCpfToEDocsTitles?: boolean = false;
    formFieldNameAddedToEDocsTitles?: string;

    shouldAddInfoToEDocsTitles_Additional?: boolean = false;
    addUserNameToEDocsTitles_Additional?: boolean = false;
    addUserCpfToEDocsTitles_Additional?: boolean = false;
    formFieldNameAddedToEDocsTitles_Additional?: string;
}

export class ConfigSchema {
    taskForward? = new ConfigSchemaTaskForward();
    taskForm? = new ConfigSchemaTaskForm();
    taskStartInboundApi?= new ConfigSchemaTaskStartInboundApi();
    taskApprove? = new ConfigSchemaTaskApprove();
    taskAcknowledge? = new ConfigSchemaTaskAcknowledge();
    taskRegisterProcess? = new ConfigSchemaTaskRegisterProcess();
    taskDispatchProcess? = new ConfigSchemaTaskDispatchProcess();
    taskOutboundApi? = new ConfigSchemaTaskOutboundApi();
    taskGatewayRules? = new ConfigSchemaTaskGatewayRules();
    taskGatewayPaths? = new ConfigSchemaTaskGatewayPaths();
    taskFinishContent? = new ConfigSchemaTaskFinishContent();
}

export class ConfigSchemaSectionAccessLevel {
    id?: AccessLevelType = AccessLevelType.Organizational;
    name?: string = AccessLevelTypeDescription.get(AccessLevelType.Organizational);
    legalReasoningsIds?: string[] = [];
    legalReasoningsNames?: string[] = [];
}

export class ConfigSchemaSectionDocumentClass {
    id?: string = null;
    name?: string = null;
}

export class ConfigSchemaSectionEDocsLocation {
    id?: string = null;
    name?: string = null;
    type?: LocationType = null;
}

export class ConfigSchemaTaskForward {
    toImmediateSupervisor?: boolean = false;
    conditionalRecipients?: ConditionalRecipient[] = [];
    recipient?: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
    accessLevel?: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
}

export class ConfigSchemaTaskForm {
    documentClass?: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();
    accessLevel?: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
}

export class ConfigSchemaTaskStartInboundApi {
    fileUrls?: any;
}

export class ConfigSchemaTaskApprove extends ConfigSchemaTaskForm { }

export class ConfigSchemaTaskAcknowledge extends ConfigSchemaTaskForm { }

export class ConfigSchemaTaskRegisterProcess {
    processLocationId?: string = null;
    processLocationName?: string = '';
    processSummary?: string = '';
    stakeholders?: Stakeholder[] = [];
    processClass?: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();
}

export class ConfigSchemaTaskDispatchProcess {
    dispatchSummary?: string = '';
    toPublicAgent?: boolean = false;
    conditionalRecipients?: ConditionalRecipient[] = [];
    accessLevel?: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    dispatchRecipient?: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();
}

export class ConfigSchemaTaskOutboundApi {
    publicSystemName?: string = '';
    httpMethod?: HttpMethod = HttpMethod.Get;
    url?: string = null;
    urlParameters?: any = {};
    ignoreErrors?: boolean;
    backgroundExecution?: boolean;
    errorMessage?: string = null;
    authentication?: AuthenticationModel = new AuthenticationModel();
    resolver?: ApiResolver = new ApiResolver();
    data?: any = {};
}

export class ConfigSchemaTaskGatewayRules {
    httpSuccessRule?: boolean = null;
    message?: string = '';
    propertyKey?: string = null;
    ruleOperator?: GatewayRuleOperator = null;
    propertyValue?: any = null;
}

export class ConfigSchemaTaskGatewayPaths {
    firstGatewayPathName?: string = 'Sequência A';
    secondGatewayPathName?: string = 'Sequência B';
    logicalOperator?: ('and' | 'or') = 'and';
    conditions?: GatewayPathsCondition[] = [];
}

export class ConditionalRecipient {
    formFieldId?: string = null;
    value?: string = null;
    isOperationEqual?: boolean = true;
    timestamp?: string = new Date().toISOString();
    recipient?: ConfigSchemaSectionEDocsLocation = new ConfigSchemaSectionEDocsLocation();

    public constructor(init?: Partial<ConditionalRecipient>) {
        Object.assign(this, init);
    }
}

export class GatewayPathsCondition {
    formField?: string;
    ruleOperator?: GatewayRuleOperator = GatewayRuleOperator.Equal;
    referenceValue?: any;
    referenceValueDisabled?: boolean = false;
    timestamp?: string = new Date().toISOString();
}

export class ConfigSchemaTaskFinishContent {
    contentType?: FinishContentType = null;
    message?: string = '';
    waitingMessage?: string = '';
    documentClass?: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();
    accessLevel?: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    attachments?: FinishContentAttachment[] = [];
}

export class FinishContentAttachment {
    id?: string = null;
    label?: string = '';
    documentClass?: ConfigSchemaSectionDocumentClass = new ConfigSchemaSectionDocumentClass();
    accessLevel?: ConfigSchemaSectionAccessLevel = new ConfigSchemaSectionAccessLevel();
    documentLegalValueCombination?: DocumentLegalValueCombination = null;
    timestamp?: string = null;
}

export enum LocationType {
    Organization = 0,
    Group = 1,
    Comission = 2
}

export class Stakeholder {
    id?: string = null;
    name?: string = null;
    citizenCpfFormField?: string = null;
    isCitizenFlowInstanceOwner?: boolean = false;
    companyName?: string = null;
    companyNameFormField?: string = null;
    cnpj?: string = null;
    cnpjFormField?: string = null;
    email?: string = null;
    emailFormField?: string = null;
    type: StakeholderType = null;
    timestamp?: string = new Date().toISOString();
    isMandatory?: boolean = true;
    shouldIgnore: boolean = false;

    public constructor(init?: Partial<Stakeholder>) {
        Object.assign(this, init);
    }
}

export enum StakeholderType {
    Patriarch = 1,
    Organization = 2,
    Unit = 3,
    Group = 4,
    Comission = 5,
    PublicAgent = 6,
    Citizen = 7,
    Company = 8
}

export const StakeholderTypeDescription = new Map<number, string>([
    [StakeholderType.Patriarch, 'Patriarca'],
    [StakeholderType.Organization, 'Órgão'],
    [StakeholderType.Unit, 'Setor'],
    [StakeholderType.Group, 'Grupo'],
    [StakeholderType.Comission, 'Comissão'],
    [StakeholderType.PublicAgent, 'Servidor'],
    [StakeholderType.Citizen, 'Cidadão'],
    [StakeholderType.Company, 'Pessoa Jurídica']
]);

export enum FinishContentType {
    Text = 1,
    Attachment = 2
}

// #region [Task API]
export enum AuthenticationSchemeType {
    None = 0,
    Basic = 3,
    ApiKey = 4,
    Jwt = 5
}

export const AuthenticationSchemeTypeDescription = new Map<number, string>([
    [AuthenticationSchemeType.None, 'Sem Autenticação'],
    [AuthenticationSchemeType.Basic, 'Básica'],
    [AuthenticationSchemeType.ApiKey, 'API Key'],
    [AuthenticationSchemeType.Jwt, 'JWT']
]);

export enum HttpMethod {
    Get = 0,
    Post = 1,
    Put = 2,
    Delete = 3
}

export const HttpMethodDescription = new Map<number, string>([
    [HttpMethod.Get, 'GET'],
    [HttpMethod.Post, 'POST'],
    [HttpMethod.Put, 'PUT'],
    [HttpMethod.Delete, 'DELETE']
]);

export class AuthenticationModel {
    authenticationScheme?: AuthenticationSchemeType = AuthenticationSchemeType.None;
    basicOrApiKey?: string = null;
    clientId?: string = null;
    clientSecret?: string = null;
    scopes?: string = null;
    tokenEndpoint?: string = null;
}

export class ApiResolver {
    id?: string = null;
    name?: string = null;
    type?: LocationType = null;
}

export enum GatewayRuleOperator {
    Equal = 0,
    NotEqual = 1,
    GreaterThan = 2,
    GreaterOrEqual = 3,
    LessThan = 4,
    LessOrEqual = 5,
    IsTrue = 6,
    IsFalse = 7,
    IsNull = 8,
    IsNotNull = 9,
    IsEmpty = 10,
    IsNotEmpty = 11,
    Contains = 12
}

export const GatewayRuleOperatorDescription = new Map<number, string>([
    [GatewayRuleOperator.Equal, 'é igual a'],
    [GatewayRuleOperator.NotEqual, 'é diferente de'],
    [GatewayRuleOperator.GreaterThan, 'é maior que'],
    [GatewayRuleOperator.GreaterOrEqual, 'é maior que ou igual a'],
    [GatewayRuleOperator.LessThan, 'é menor que'],
    [GatewayRuleOperator.LessOrEqual, 'é menor que ou igual a'],
    [GatewayRuleOperator.IsTrue, 'é verdadeiro'],
    [GatewayRuleOperator.IsFalse, 'é falso'],
    [GatewayRuleOperator.IsNull, 'é nulo'],
    [GatewayRuleOperator.IsNotNull, 'não é nulo'],
    [GatewayRuleOperator.IsEmpty, 'é vazio'],
    [GatewayRuleOperator.IsNotEmpty, 'não é vazio'],
    [GatewayRuleOperator.Contains, 'contém']
]);
// #endregion
