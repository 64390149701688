import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfigSchema, FinishContentAttachment, FinishContentType } from '../../../models/config-schema.model';
import { DocumentLegalValueCombinationDescription, Papel } from '../../../models/edocs.model';
import { FlowObjectDefinition, FlowObjectInstance, FlowObjectInstanceState, FlowObjectType } from '../../../models/flow-object.model';
import { FlowDefinition, FlowInstance } from '../../../models/flow.model';
import { PdfUploadValue } from '../../../models/helper-classes.model';
import { InputDataTaskFinishContent, InputDataTaskForm, OutputDataTaskFinishContent } from '../../../models/input-output-data.model';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { Enums } from '../../../shared/enums';
import { Utils } from '../../../shared/utils';
import { PdfPreviewDialogComponent } from '../../pdf-preview-dialog/pdf-preview-dialog.component';

@Component({
    selector: 'flow-object-finish-content',
    templateUrl: './flow-object-finish-content.component.html',
    styleUrls: ['./flow-object-finish-content.component.scss']
})
export class FlowObjectFinishContentComponent implements OnInit, AfterViewInit {
    // #region [Type properties]
    DocumentLegalValueCombinationDescription: typeof DocumentLegalValueCombinationDescription = DocumentLegalValueCombinationDescription;
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [const]
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;
    // #endregion

    // #region [properties]
    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskFinishContent;
    outputData: OutputDataTaskFinishContent;
    forwardingProtocol: string = '(N/D)';
    processProtocol: string = '(N/D)';
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;
    waitingMessage: SafeHtml = this.DEFAULT_LOADING_TEXT;
    configSchema: ConfigSchema = null;
    papeisSelector: Papel[] = [];
    currentDocuments: any[] = [];
    forgotPapel: boolean = false;
    // #endregion

    // #region [getters]
    get hasPendingTask(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.Started;
    }
    get isTaskPublicAgentActor(): boolean {
        return this.authService?.user?.isPublicAgent
            && this.model?.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id);
    }
    get isReadOnly(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.NotStarted
        ].includes(this.model?.stateId);
    }
    get isTextContentType(): boolean {
        return this.configSchema?.taskFinishContent?.contentType == FinishContentType.Text;
    }
    get isMissingMessage(): boolean {
        return Utils.isNullOrEmpty(this.inputData?.message) || this.inputData.message.trim().length < 5;
    }
    get isMissingAttachments(): boolean {
        return this.inputData.attachments?.length != this.configSchema?.taskFinishContent?.attachments?.length;
    }
    get isTaskCancelled(): boolean {
        return [
            FlowObjectInstanceState.AutomaticallyCancelled,
            FlowObjectInstanceState.ManuallyCancelled
        ].includes(this.model?.stateId);
    }
    get isPlural(): boolean {
        return this.configSchema?.taskFinishContent?.attachments?.length > 1;
    }
    get dateString(): string {
        return this.model == null ? '' : new Date(this.model.updateDate).toLocaleString();
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputFlowInstance: FlowInstance;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectInstance>();
    // #endregion

    constructor(
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private authService: AuthService,
        private cookieService: CookieService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);
        this.configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;
        this.inputData = JSON.parse(this.model.inputData) as InputDataTaskFinishContent;
        this.outputData = this.model.outputData == null
            ? null
            : Object.assign(new OutputDataTaskFinishContent(), JSON.parse(this.model.outputData));

        // #region [protocolo de Encaminhamento]
        if (Utils.isNullOrEmpty(this.inputData.eDocsData.forwardingId)) {
            this.toastr.error(Enums.Messages.ForwardingProtocolMissingError, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const response_Encaminhamento = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_Encaminhamento.isSuccess) {
            this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamento = response_Encaminhamento.data;
        this.forwardingProtocol = encaminhamento.protocolo;
        // #endregion

        // #region [protocolo de Processo]
        if (!Utils.isNullOrEmpty(this.inputData.eDocsData.processId)) {
            const response_Processo = await this.eDocsService.getProcesso(this.inputData.eDocsData.processId);

            if (!response_Processo.isSuccess) {
                this.toastr.error(response_Processo.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.processProtocol = response_Processo.data.protocolo;
        }
        // #endregion

        // #region [mensagem pública customizada]
        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getParsedMessageHtml(
            this.cookieService,
            this.flowObjectDefinition.publicMessageHtml,
            this.forwardingProtocol,
            this.processProtocol
        ));
        // #endregion

        // #region [mensagem de espera do requerente]
        let bufferMessage = this.resolveMessageFormTags(this.configSchema.taskFinishContent.waitingMessage);

        this.waitingMessage = this.sanitizer.bypassSecurityTrustHtml(Utils.getParsedMessageHtml(
            this.cookieService,
            bufferMessage,
            this.forwardingProtocol,
            this.processProtocol
        ));
        // #endregion

        // #region [mensagem do conteúdo]
        if (this.isTextContentType) {
            this.inputData.message = this.resolveMessageFormTags(this.configSchema.taskFinishContent.message);
        }
        // #endregion

        // #region [seletor de papéis]
        const response_UsuarioPapeisEncaminhamento = await this.eDocsService.getUsuarioPapeisEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_UsuarioPapeisEncaminhamento.isSuccess) {
            this.toastr.error(response_UsuarioPapeisEncaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.papeisSelector = response_UsuarioPapeisEncaminhamento.data;

        // força o usuário a selecionar um papel manualmente
        setTimeout(() => this.inputData.eDocsData.signerId = null, 50);
        // #endregion

        // #region [documentos capturados na Tarefa corrente]
        if (!Utils.isNullOrEmpty(this.outputData)) {
            let filtered = encaminhamento.documentosEncaminhamento.filter(x =>
                x.documento.encaminhamentoId == this.outputData.eDocsData.forwardingId
                && !x.controle
            );

            for (let item of filtered) {
                this.currentDocuments.push({
                    id: item.documento.id,
                    registro: item.documento.registro,
                    filename: `${item.documento.nome}.${item.documento.extensao}`
                });
            }
        }
        // #endregion
    }

    ngAfterViewInit() {
        if (this.hasPendingTask && this.inputData.eDocsData.signerId == null) {
            this.inputData.eDocsData.signerId = this.authService.user.papeis.find(x => x.prioritario).guid;
        }
    }

    // ======================
    // public methods
    // ======================

    async openDocumentModal(id: string) {
        this.spinner.show('flowInstance');

        const response = await this.eDocsService.getDocumentoDownloadUrl(id);

        this.spinner.hide('flowInstance');

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        setTimeout(() => {
            this.dialog.open(PdfPreviewDialogComponent, {
                data: {
                    content: response.data
                }
            });
        }, 200);
    }

    onPdfUploadChange(event: PdfUploadValue, attachment: FinishContentAttachment) {
        let buffer = (this.inputData.attachments || []).filter(x => x.id != attachment.id);
        buffer.push({
            id: attachment.id,
            identificadorTemporarioArquivoNaNuvem: event.minioKey[0]
        });
        this.inputData.attachments = buffer;
    }

    getDocumentLegalValueCombination(item: FinishContentAttachment): string {
        return this.DocumentLegalValueCombinationDescription.get(+item.documentLegalValueCombination).split(':')[1].trim();
    }

    getLegalReasoningsNames(item: FinishContentAttachment): string {
        return item.accessLevel.legalReasoningsNames.map(x => x.split(' - ')[1].trim().toLocaleUpperCase()).join('; ');
    }

    submit() {
        if (this.isTextContentType && this.isMissingMessage) {
            this.toastr.warning(Enums.Messages.MessageMinlengthError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        if (!this.isTextContentType && this.isMissingAttachments) {
            this.toastr.warning(Enums.Messages.MissingAttachmentsError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        if (Utils.isNullOrEmpty(this.inputData.eDocsData.signerId)) {
            this.toastr.warning(Enums.Messages.NoSignerIdError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            this.forgotPapel = true;
            return;
        }

        this.model.inputData = JSON.stringify(this.inputData);

        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================

    private resolveMessageFormTags(message: string): string {
        if (Utils.isNullOrEmpty(message) == null) return '';

        let formData = this.getFormData();
        if (formData == null) return '';

        let bufferMessage = message;
        let tags = bufferMessage.match(/\{\|([\w]+)\|\}/g);
        if (tags?.length > 0) {
            let formFields = tags.map(x => x.replace(/[^\w]/g, ''));

            for (let i = 0; i < tags.length; i++) {
                bufferMessage = bufferMessage.replaceAll(
                    tags[i],
                    formData[formFields[i]] || ''
                ).replace(/ +/g, ' ').trim();
            }
        }

        return bufferMessage;
    }

    private getFormData(): any {
        let formTaskDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formTaskDefinition == null) return null;

        let formTaskInstance = this.inputFlowInstance.flowObjectInstances.find(x => x.flowObjectDefinitionId == formTaskDefinition.id);
        let formData = JSON.parse(formTaskInstance.inputData) as InputDataTaskForm;

        return formData.data;
    }
}
