import { AppComponent } from './app.component';
import { ErrorComponent } from './core/error/error.component';
import { BaseComponent } from './components/base/base.component';
import { HeaderComponent } from './components/common-layout/header/header.component';
import { FooterComponent } from './components/common-layout/footer/footer.component';
import { UserMenuComponent } from './components/common-layout/header/user-menu/user-menu.component';
import { MainMenuComponent } from './components/common-layout/header/main-menu/main-menu.component';
import { NotificationsComponent } from './components/common-layout/header/notifications/notifications.component';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeItemComponent } from './components/iterables/home-item/home-item.component';
import { FlowObjectApproveComponent } from './components/flow-objects/flow-object-approve/flow-object-approve.component';
import { FlowObjectAcknowledgeComponent } from './components/flow-objects/flow-object-acknowledge/flow-object-acknowledge.component';
import { FlowObjectRegisterProcessComponent } from './components/flow-objects/flow-object-register-process/flow-object-register-process.component';
import { FlowObjectDispatchProcessComponent } from './components/flow-objects/flow-object-dispatch-process/flow-object-dispatch-process.component';
import { FlowObjectFinishComponent } from './components/flow-objects/flow-object-finish/flow-object-finish.component';
import { FlowObjectFinishContentComponent } from './components/flow-objects/flow-object-finish-content/flow-object-finish-content.component';
import { FlowObjectOutboundApiComponent } from './components/flow-objects/flow-object-outbound-api/flow-object-outbound-api.component';
import { FlowObjectMidwayOutboundApiComponent } from "./components/flow-objects/flow-object-midway-outbound-api/flow-object-midway-outbound-api.component";
import { FlowObjectGatewayRulesComponent } from './components/flow-objects/flow-object-gateway-rules/flow-object-gateway-rules.component';
import { FlowObjectFormComponent } from './components/flow-objects/flow-object-form/flow-object-form.component';
import { FlowObjectStartInboundApiComponent } from './components/flow-objects/flow-object-start-inbound-api/flow-object-start-inbound-api.component';
import { FlowObjectForwardComponent } from './components/flow-objects/flow-object-forward/flow-object-forward.component';
import { FlowDefinitionComponent } from './pages/flow-definition/flow-definition.component';
import { FlowDefinitionListComponent } from './pages/flow-definition-list/flow-definition-list.component';
import { FlowDefinitionItemComponent } from './components/iterables/flow-definition-item/flow-definition-item.component';
import { FlowTargetSectionComponent } from './components/flow-target-section/flow-target-section.component';
import { FlowInstanceComponent } from './pages/flow-instance/flow-instance.component';
import { FlowInstanceListComponent } from './pages/flow-instance-list/flow-instance-list.component';
import { FlowInstanceItemComponent } from './components/iterables/flow-instance-item/flow-instance-item.component';
import { TestsComponent } from './pages/tests/tests.component';
import { MjolnirComponent } from './pages/mjolnir/mjolnir.component';
import { TermosUsoComponent } from './pages/termos-uso/termos-uso.component';
import { PapelSelectorComponent } from './components/papel-selector/papel-selector.component';
import { PdfUploadComponent } from './components/flow-objects/flow-object-form/formio/pdf-upload/pdf-upload.component';
import { PdfUploadMultipleComponent } from './components/flow-objects/flow-object-form/formio/pdf-upload-multiple/pdf-upload-multiple.component';
import { DateTimePickerComponent } from './components/flow-objects/flow-object-form/formio/datetime-picker/datetime-picker.component';
import { DatePickerComponent } from './components/flow-objects/flow-object-form/formio/date-picker/date-picker.component';
import { PdfPreviewDialogComponent } from './components/pdf-preview-dialog/pdf-preview-dialog.component';
import { DomChangeDirective } from './shared/dom-change.directive';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularResizedEventModule } from 'angular-resize-event';
import { FormioModule } from '@formio/angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMatPopoverModule } from './components/ngx-mat-popover/ngx-mat-popover.module';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './core/http/auth.interceptor';

import { ConfirmationService } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { registerPdfUploadComponent } from './components/flow-objects/flow-object-form/formio/pdf-upload/pdf-upload.formio';
import { registerPdfUploadMultipleComponent } from './components/flow-objects/flow-object-form/formio/pdf-upload-multiple/pdf-upload-multiple.formio';
import { registerDateTimePickerComponent } from './components/flow-objects/flow-object-form/formio/datetime-picker/datetime-picker.formio';
import { registerDatePickerComponent } from './components/flow-objects/flow-object-form/formio/date-picker/date-picker.formio';
import { CustomDateAdapter } from './shared/custom-date-adapter';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        BaseComponent,
        HeaderComponent,
        FooterComponent,
        UserMenuComponent,
        MainMenuComponent,
        NotificationsComponent,
        FilterMenuComponent,
        HomeComponent,
        HomeItemComponent,
        FlowObjectApproveComponent,
        FlowObjectAcknowledgeComponent,
        FlowObjectRegisterProcessComponent,
        FlowObjectDispatchProcessComponent,
        FlowObjectFinishComponent,
        FlowObjectFinishContentComponent,
        FlowObjectOutboundApiComponent,
        FlowObjectMidwayOutboundApiComponent,
        FlowObjectGatewayRulesComponent,
        FlowObjectFormComponent,
        FlowObjectStartInboundApiComponent,
        FlowObjectForwardComponent,
        PapelSelectorComponent,
        PdfUploadComponent,
        PdfUploadMultipleComponent,
        DateTimePickerComponent,
        DatePickerComponent,
        PdfPreviewDialogComponent,
        FlowDefinitionComponent,
        FlowDefinitionListComponent,
        FlowDefinitionItemComponent,
        FlowTargetSectionComponent,
        FlowInstanceComponent,
        FlowInstanceListComponent,
        FlowInstanceItemComponent,
        TestsComponent,
        MjolnirComponent,
        TermosUsoComponent,
        DomChangeDirective
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        DragDropModule,
        AngularResizedEventModule,
        FormioModule,
        NgxSpinnerModule,
        NgxMatPopoverModule,
        NgxMatMomentModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatTooltipModule,
        MatChipsModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDialogModule,
        MatMenuModule,
        MatDatepickerModule,
        MatIconModule,
        ToolbarModule,
        ButtonModule,
        ConfirmDialogModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            enableHtml: true,
            progressBar: true,
            progressAnimation: 'increasing'
        }),
        AppRoutingModule,
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        ConfirmationService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: NgxMatDateAdapter, useClass: CustomDateAdapter },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "DD/MM/YYYY, [às] HH:mm:ss"
                },
                display: {
                    dateInput: "DD/MM/YYYY, [às] HH:mm:ss",
                    monthYearLabel: "MMM YYYY",
                    dateA11yLabel: "LLL",
                    monthYearA11yLabel: "MMMM YYYY"
                }
            }
        },
        AuthService,
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        registerPdfUploadComponent(injector);
        registerPdfUploadMultipleComponent(injector);
        registerDateTimePickerComponent(injector);
        registerDatePickerComponent(injector);
    }
}
