import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ElementRef,
    ViewChild,
    QueryList,
    ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormioCustomComponent } from '@formio/angular';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';
import { PdfUploadValue } from '../../../../../models/helper-classes.model';
import { EDocsService } from '../../../../../services/edocs.service';
import { Enums } from '../../../../../shared/enums';
import { Utils } from '../../../../../shared/utils';
import { PdfPreviewDialogComponent } from '../../../../pdf-preview-dialog/pdf-preview-dialog.component';

@Component({
    selector: 'pdf-upload-multiple',
    templateUrl: './pdf-upload-multiple.component.html',
    styleUrls: ['./pdf-upload-multiple.component.scss']
})
export class PdfUploadMultipleComponent implements FormioCustomComponent<any>, OnInit {
    // #region [ViewChild]
    @ViewChild('anchorFileRef') anchorFileRef: ElementRef;
    @ViewChildren('fileRef') fileRef: QueryList<ElementRef>;
    // #endregion

    // #region [const]
    private MAX_SIZE_MB: number = 100 as const;
    // #endregion

    // #region [properties]
    fileNames: string[] = [];
    rerenderer: number[] = [0];
    msArray: number[] = [0];
    fileRefsFile: any[] = [];
    shouldPreventUploadArray: boolean[] = [false];
    activeButtonArray: ('main' | 'prevent' | 'cancelled' | 'progress' | 'done')[] = ['main'];
    msCurrent: number = 0;
    shouldPreventUploadCurrent: boolean = false;
    activeButtonCurrent: ('main' | 'prevent' | 'cancelled' | 'progress' | 'done') = 'main';
    // #endregion

    // #region [getters]
    get hasValue(): boolean {
        return this.value != null && JSON.stringify(this.value) != '{}';
    }
    // #endregion

    // #region [Input/Output]
    @Input() maxFiles: number;
    @Input() disabled: boolean;
    @Input() value: any;
    @Output() valueChange = new EventEmitter<PdfUploadValue>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        // contorno de bug do Formio: aguarda por até 2s para que o "value" esteja disponível.
        // Lifecycle do Angular não funciona como deveria pois o componente existe no
        // contexto do Formio e recebe os valores nos parâmetros de @Input assincronamente
        this.value = this.value || {};
        let count = -1;
        let interval = setInterval(() => {
            count++;

            if (this.value?.fileName?.length > 0) {
                this.value.fileName.forEach(x => {
                    this.fileNames.push(x);
                    this.shouldPreventUploadArray.push(false);
                    this.activeButtonArray.push('main');
                    this.msArray.push(0);
                });
                clearInterval(interval);
            }

            if (count == 10) {
                this.value = {
                    isCustomFileComponent: true,
                    fileName: [],
                    minioKey: []
                };

                clearInterval(interval);
            }
        }, 200);
    }

    // ======================
    // public methods
    // ======================

    changeFile(event, idx?: number) {
        const file = event.target.files[0] as File;

        // caso a extensão do arquivo não seja PDF
        if (file.name.split('.').slice(-1)[0].toLowerCase() != 'pdf') {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfFilesOnly, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // caso o tamanho do arquivo seja maior que o máximo estabelecido
        if (file.size > this.MAX_SIZE_MB * 1024 * 1024) {
            // reinicia valores do input e do componente Formio
            this.removeFile(event);
            this.toastr.error(Enums.Messages.PdfMaxSizeLimit.replace('{0}', this.MAX_SIZE_MB.toString()), Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        // reserva um spot na fila de upload de arquivos
        let fileQueueSpot = Guid.create().toString();
        window['_$_fileQueue'] = window['_$_fileQueue'] || [];
        window['_$_fileQueue'].push(fileQueueSpot);

        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = () => {
            // exibe o botão para cancelar o upload
            this.setActiveButton('prevent', idx);

            setTimeout(async () => {
                // clicou-se para cancelar o upload
                if (this.getShouldPreventUpload(idx)) {
                    this.setShouldPreventUpload(false, idx);
                    this.setActiveButton('main', idx);
                    return;
                }

                // início da fase de loading
                this.setActiveButton('progress', idx);
                let interval = setInterval(() => {
                    this.setMs(new Date().getMilliseconds(), idx);
                }, 100);

                // #region [upload do arquivo no Minio]
                const response_Get = await this.eDocsService.getGerarUrl(file.size);

                if (!response_Get.isSuccess) {
                    this.toastr.error(response_Get.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                let gerarUrl = response_Get.data;

                // monta um FormData para facilitar o POST do arquivo
                let formData = new FormData();
                for (let key in gerarUrl.body) {
                    formData.append(key, gerarUrl.body[key])
                }

                // binário tem que vir depois dos parâmetros no payload se não o Minio do E-Docs explode
                formData.append('file', file);

                // realiza o POST via fetch para facilitar o POST de conteúdo "multipart/form-data"
                const response_Post = await fetch(gerarUrl.url, {
                    method: 'post',
                    body: formData
                });

                if (!response_Post.ok) {
                    this.toastr.error(
                        Enums.Messages.MinioPostError.replace('{0}', response_Post.status.toString()).replace('{1}', response_Post.statusText),
                        Enums.Messages.Error,
                        Utils.getToastrErrorOptions()
                    );
                    return;
                }
                // #endregion

                // libera o spot na fila de upload de arquivos
                window['_$_fileQueue'] = (window['_$_fileQueue'] || []).filter(x => x != fileQueueSpot);

                // fim da fase de loading
                clearInterval(interval);
                this.setActiveButton('done', idx);

                setTimeout(() => {
                    // retorno ao modo default
                    this.setActiveButton('main', idx);
                    this.activeButtonArray.push('main');
                    this.shouldPreventUploadArray.push(false);

                    // registro e publicação das alterações
                    if (JSON.stringify(this.value) == '{}') {
                        this.value = null;
                    }

                    this.value = this.value || {
                        isCustomFileComponent: true,
                        fileName: [],
                        minioKey: []
                    };

                    if (idx != null) {
                        this.fileNames[idx] = file.name;
                        this.value.fileName[idx] = file.name;
                        this.value.minioKey[idx] = gerarUrl.identificadorTemporarioArquivoNaNuvem;
                    } else {
                        this.fileNames.push(file.name);
                        this.value.fileName.push(file.name);
                        this.value.minioKey.push(gerarUrl.identificadorTemporarioArquivoNaNuvem);
                    }

                    if (idx != null) {
                        let fileRefs = this.fileRef.toArray();
                        this.fileRefsFile[idx] = fileRefs[idx].nativeElement.files[0];
                    } else {
                        this.fileRefsFile.push(this.anchorFileRef.nativeElement.files[0]);
                    }

                    this.anchorFileRef.nativeElement.value = '';

                    this.valueChange.emit(this.value);
                }, 2000);
            }, 3000);
        };
    }

    removeFile(idx: number) {
        this.fileNames.splice(idx, 1);
        this.value.fileName.splice(idx, 1);
        this.fileRefsFile.splice(idx, 1);

        if (this.value.fileName.length == 0) {
            this.value = {};
        } else {
            this.value.minioKey.splice(idx, 1);
        }

        this.valueChange.emit(this.value);

        this.rerenderer.pop();
        setTimeout(() => this.rerenderer.push(0), 100);
    }

    viewFile(idx: number) {
        this.dialog.open(PdfPreviewDialogComponent, {
            data: {
                content: URL.createObjectURL(this.fileRefsFile[idx])
            }
        });
    }

    candAddMoreFiles(): boolean {
        return (
            // caso o componente esteja em modo de edição (/flow-definition)
            !this.disabled
            && (
                // caso o componente não tenha terminado de carregar ainda
                !this.hasValue
                // limite de arquivos definido no Construtor de Formulário
                || this.value?.fileName?.length < this.maxFiles
            )
        );
    }

    getProgressState(idx?: number): ('start' | 'half' | 'end') {
        if (idx != null) {
            if (this.msArray[idx] <= 333) {
                return 'start';
            } else if (this.msArray[idx] <= 666) {
                return 'half';
            }

            return 'end';
        } else {
            if (this.msCurrent <= 333) {
                return 'start';
            } else if (this.msCurrent <= 666) {
                return 'half';
            }

            return 'end';
        }
    }

    preventUpload(idx?: number) {
        if (idx != null) {
            this.shouldPreventUploadArray[idx] = true;
            this.activeButtonArray[idx] = 'cancelled';
        } else {
            this.shouldPreventUploadCurrent = true;
            this.activeButtonCurrent = 'cancelled';
        }
    }

    // ======================
    // private methods
    // ======================

    private getShouldPreventUpload(idx?: number): boolean {
        if (idx != null) {
            return this.shouldPreventUploadArray[idx];
        }

        return this.shouldPreventUploadCurrent;
    }

    private setShouldPreventUpload(value, idx?: number) {
        if (idx != null) {
            this.shouldPreventUploadArray[idx] = value;
        } else {
            this.shouldPreventUploadCurrent = value;
        }
    }

    private setActiveButton(value, idx?: number) {
        if (idx != null) {
            this.activeButtonArray[idx] = value;
        } else {
            this.activeButtonCurrent = value;
        }
    }

    private setMs(value, idx?: number) {
        if (idx != null) {
            this.msArray[idx] = value;
        } else {
            this.msCurrent = value;
        }
    }
}
