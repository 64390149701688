<div *ngIf="model != null" class="wrapper px-2 py-4">
    <!-- Loading -->
    <ngx-spinner name="flowInstance" bdColor="#000000aa" size="medium" color="#64b2cd" type="line-scale-pulse-out-rapid" [fullScreen]="true">
        <h5>Processando...</h5>
    </ngx-spinner>

    <div class="container-flex d-flex justify-content-center">
        <!-- Usuário sem permissão -->
        <div class="forbidden card" *ngIf="!isFlowInstanceRelatedToUser(); else instanceAreaTemplate">
            <b>Você não possui permissão para acessar este Fluxo!</b>
        </div>

        <ng-template #instanceAreaTemplate>
            <div class="instance-area card">
                <!-- Header -->
                <div class="header">
                    <!-- Title -->
                    <div class="title">
                        <!-- Breadcrumb -->
                        <ng-container *ngIf="!isFromConecta">
                            <a [routerLink]="[Enums.PagesPaths.FlowInstanceList]"><span>Meus Fluxos</span></a>
                            <i class="fas fa-chevron-right mx-2"></i>
                        </ng-container>

                        <!-- Nome -->
                        <div class="flow-instance-name">
                            <span class="h5 text-highlight m-0">{{model.name}}</span>
                        </div>
                    </div>

                    <!-- Tags -->
                    <div class="tags">
                        <!-- Caixa de Fluxos -->
                        <span class="flow-inbox relevant-info-font text-highlight"
                              matTooltip="Setor responsável pelo Fluxo: {{patriarchAcronym}} - {{organizationAcronym}} - {{unitAcronym}}"
                              *ngIf="patriarchAcronym != null && organizationAcronym != null && unitAcronym != null"
                        >{{patriarchAcronym}} - {{organizationAcronym}} - {{unitAcronym}}</span>

                        <!-- Versão -->
                        <span class="version-tag px-1 py-0" matTooltip="Versão instanciada">Versão {{model.version}}</span>
                    </div>
                </div>

                <hr>

                <!-- Descrição e Informações -->
                <div class="card description-area mx-1 mt-2 mb-4" #descriptionAreaRef
                     *ngIf="model.flowDefinition?.publishedDescription != null && model.flowDefinition?.publishedDescription?.trim() != ''"
                     [class.show]="isShowingDescription"
                >
                    <div class="description-tag relevant-info-font" (click)="toggleDescription()">
                        <i class="toggle-description fas fa-chevron-circle-right mr-1" [class.on]="isShowingDescription"></i>
                        <span>Descrição e Informações</span>
                        <i class="toggle-description fas fa-chevron-circle-left ml-1" [class.on]="isShowingDescription"></i>
                    </div>
                    <div class="description-message-container" [class.show]="isShowingDescription">
                        <div class="description-message" #descriptionMessageRef
                             [hidden]="!isShowingDescription"
                             [innerHtml]="sanitizer.bypassSecurityTrustHtml(getDescriptionHtml())"
                        ></div>
                    </div>
                </div>

                <!-- Canvas -->
                <ng-container *ngFor="let flowObjectInstance of drawableFlowObjectInstances; let idx = index">
                    <div class="task-area card m-1">
                        <!-- Ordem de execução da Tarefa -->
                        <div class="task-order" matTooltip="Ordem de execução da Tarefa">
                            <span class="d-inline-block">{{idx + 1}}</span>
                        </div>

                        <!-- Header -->
                        <div class="task-header px-1">
                            <div class="first-group">
                                <!-- Expande/recolhe -->
                                <i class="toggle-body fas fa-chevron-circle-right"
                                   [class.on]="!shouldKeepTaskBodyClosed(idx, flowObjectInstance)"
                                   (click)="toggleTaskBody($event)"
                                ></i>

                                <!-- Ícone da Tarefa -->
                                <div class="task-icon-container mr-2"
                                     matTooltip="Tarefa de {{FlowObjectTypeCategory.get(+flowObjectInstance.flowObjectDefinition.typeId)}}"
                                     (click)="toggleTaskBody($event)"
                                >
                                    <i class="fas fa-file-alt"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.StartForm"
                                    ></i>
                                    <i class="fas fa-external-link-square-alt"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.StartInboundApi"
                                    ></i>
                                    <i class="fas fa-paper-plane"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskForward"
                                    ></i>
                                    <i class="fas fa-check"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge"
                                    ></i>
                                    <i class="fas fa-external-link-alt"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskMidwayOutboundApi"
                                    ></i>
                                    <i class="fas fa-suitcase"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskRegisterProcess"
                                    ></i>
                                    <i class="fas fa-forward"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskDispatchProcess"
                                    ></i>
                                    <i class="fas fa-thumbs-up"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.GatewayApprove"
                                    ></i>
                                    <i class="icon fas fa-gavel"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.GatewayRules"
                                    ></i>
                                    <i class="fas fa-stop-circle"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.Finish"
                                    ></i>
                                    <i class="fas fa-external-link-alt"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.FinishOutboundApi"
                                    ></i>
                                    <i class="fas fa-file-alt"
                                       *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.FinishContent"
                                    ></i>
                                </div>

                                <!-- Nome da Tarefa -->
                                <h6 class="task-name" (click)="toggleTaskBody($event)">
                                    {{flowObjectInstance.flowObjectDefinition.publicName || flowObjectInstance.flowObjectDefinition.name}}
                                </h6>

                                <!-- Mouseover info -->
                                <i class="toggle-info fas fa-info-circle" [class.timer-on]="showStartInboundApiTaskTimer"
                                   matTooltip="Expandir/recolher informações sobre a Tarefa"
                                   (click)="toggleHeaderInfo($event)"
                                ></i>
                                <i class="toggle-info fas fa-clock" *ngIf="showStartInboundApiTaskTimer"
                                   matTooltip="Expandir/recolher informações sobre a Tarefa"
                                   (click)="toggleHeaderInfo($event)"
                                ></i>
                            </div>

                            <div class="second-group display-none hidden" #secondGroupRef>
                                <!-- Usuário executor da Tarefa -->
                                <div class="finisher-info-container" *ngIf="flowObjectInstance.finisherInfo != null">
                                    <ng-container *ngIf="flowObjectInstance.stateId != FlowObjectInstanceState.AutomaticallyCancelled; else systemTemplate">
                                        <i class="fas fa-user-check" matTooltip="Usuário que executou a Tarefa"></i>
                                        <span class="relevant-info-font text-highlight" [matTooltip]="flowObjectInstance.finisherInfo">
                                            {{flowObjectInstance.finisherInfo}}
                                        </span>
                                    </ng-container>

                                    <ng-template #systemTemplate>
                                        <i class="fas fa-cog" matTooltip="Tarefa executada pelo sistema"></i>
                                        <span class="relevant-info-font text-highlight" [matTooltip]="flowObjectInstance.finisherInfo">
                                            <span>&#9658;</span>
                                            {{flowObjectInstance.finisherInfo}}
                                            <span>&#9668;</span>
                                        </span>
                                    </ng-template>
                                </div>

                                <!-- Contagem regressiva para a execução da Tarefa -->
                                <div class="timer-container mr-2" [class.time-up]="startInboundApiTimer == '00:00'" *ngIf="showStartInboundApiTaskTimer">
                                    <i class="fas fa-clock mr-1"></i>

                                    <div class="d-inline-block"
                                         matTooltip="Tempo aproximado restante para executar a Tarefa. Após este tempo, a instância do Fluxo será deletada
                                                    e será necessário iniciar nova instância a partir do sistema que o redirecionou para o E-Flow"
                                    >{{startInboundApiTimer}}</div>
                                </div>

                                <!-- Situação da Tarefa -->
                                <div class="chip-container">
                                    <i class="task-state fas fa-history" matTooltip="Situação da Tarefa"></i>

                                    <mat-chip-list class="d-inline-block"
                                                   matTooltip="{{FlowObjectInstanceStateDescription.get(+flowObjectInstance.stateId)}} em {{getDateString(flowObjectInstance)}}"
                                    >
                                        <mat-chip selected [ngClass]="{
                                            'started': flowObjectInstance.stateId == FlowObjectInstanceState.Started,
                                            'finished': [
                                                FlowObjectInstanceState.Finished,
                                                FlowObjectInstanceState.ManuallyFinished
                                            ].includes(flowObjectInstance.stateId),
                                            'cancelled': [
                                                FlowObjectInstanceState.AutomaticallyCancelled,
                                                FlowObjectInstanceState.ManuallyCancelled
                                            ].includes(flowObjectInstance.stateId),
                                            'awaiting': FlowObjectInstanceState.AwaitingAction == flowObjectInstance.stateId
                                        }">
                                            {{FlowObjectInstanceStateDescription.get(+flowObjectInstance.stateId)}}
                                        </mat-chip>
                                    </mat-chip-list>
                                    <i class="tooltip-hint fas fa-question-circle"></i>
                                </div>
                            </div>
                        </div>

                        <hr class="task-header-separator" #taskHeaderSeparatorRef
                            [class.d-none]="shouldKeepTaskBodyClosed(idx, flowObjectInstance)"
                        />

                        <!-- Body -->
                        <div class="task-container" #taskContainerRef
                             [class.d-none]="shouldKeepTaskBodyClosed(idx, flowObjectInstance)"
                             [class.hidden]="shouldKeepTaskBodyClosed(idx, flowObjectInstance)"
                        >
                            <!-- Loading -->
                            <ngx-spinner bdColor="#fbfbfb" size="medium" color="#388fad" type="line-scale-pulse-out-rapid" [fullScreen]="false">
                                <h6>Processando...</h6>
                            </ngx-spinner>

                            <!-- Mensagem de contagem regressiva esgotada -->
                            <div class="timeout-message" *ngIf="startInboundApiTimer == '00:00'; else drawTaskTemplate">
                                <p>
                                    <i class="fas fa-clock mr-2"></i>
                                    <span>Tempo para a execução da Tarefa esgotado!</span>
                                </p>
                                <span>Acesse o sistema que o redirecionou para o E-Flow e inicie um novo procedimento de redirecionamento.</span>
                            </div>

                            <!-- Switch do tipo de Tarefa a ser desenhada -->
                            <ng-template #drawTaskTemplate>
                                <div class="wrapper px-2" *ngIf="isBootstrapFinished" #wrapperRef>
                                    <flow-object-form *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.StartForm"
                                                      [inputFlowObjectInstance]="flowObjectInstance"
                                                      [inputFlowDefinition]="model.flowDefinition"
                                    ></flow-object-form>

                                    <flow-object-start-inbound-api *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.StartInboundApi"
                                                                   [inputFlowObjectInstance]="flowObjectInstance"
                                                                   [inputFlowObjectDefinition]="flowObjectInstance.flowObjectDefinition"
                                                                   [inputFlowInstance]="model"
                                                                   [inputFlowDefinition]="model.flowDefinition"
                                                                   (outputSubmitEvent)="executeTask($event)"
                                    ></flow-object-start-inbound-api>

                                    <flow-object-forward *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskForward"
                                                         [inputModel]="flowObjectInstance"
                                                         [inputFlowInstance]="model"
                                    ></flow-object-forward>

                                    <flow-object-acknowledge *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskAcknowledge"
                                                             [inputModel]="flowObjectInstance"
                                                             [inputFlowInstance]="model"
                                                             [inputFlowDefinition]="model.flowDefinition"
                                                             (outputSubmitEvent)="executeTask($event)"
                                    ></flow-object-acknowledge>

                                    <flow-object-midway-outbound-api *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskMidwayOutboundApi"
                                                                     [inputModel]="flowObjectInstance"
                                                                     (outputModelChangeEvent)="outboundApiChangeHandler($event)"
                                    ></flow-object-midway-outbound-api>

                                    <flow-object-register-process *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskRegisterProcess"
                                                                  [inputModel]="flowObjectInstance"
                                                                  [inputFlowInstance]="model"
                                                                  [inputFlowDefinition]="model.flowDefinition"
                                                                  (outputSubmitEvent)="executeTask($event)"
                                                                  (outputCancelFlowInstanceEvent)="cancelFlowInstance($event)"
                                    ></flow-object-register-process>

                                    <flow-object-dispatch-process *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.TaskDispatchProcess"
                                                                  [inputModel]="flowObjectInstance"
                                                                  [inputFlowInstance]="model"
                                                                  [inputFlowDefinition]="model.flowDefinition"
                                                                  (outputSubmitEvent)="executeTask($event)"
                                                                  (outputCancelFlowInstanceEvent)="cancelFlowInstance($event)"
                                    ></flow-object-dispatch-process>

                                    <flow-object-approve *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.GatewayApprove"
                                                         [inputModel]="flowObjectInstance"
                                                         [inputFlowInstance]="model"
                                                         [inputFlowDefinition]="model.flowDefinition"
                                                         (outputSubmitEvent)="executeTask($event)"
                                    ></flow-object-approve>

                                    <flow-object-gateway-rules *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.GatewayRules"
                                                               [inputModel]="flowObjectInstance"
                                    ></flow-object-gateway-rules>

                                    <flow-object-finish *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.Finish"
                                                        [inputModel]="flowObjectInstance"
                                                        [inputFlowDefinition]="model.flowDefinition"
                                    ></flow-object-finish>

                                    <flow-object-outbound-api *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.FinishOutboundApi"
                                                              [inputModel]="flowObjectInstance"
                                                              [inputFlowInstance]="model"
                                                              [inputFlowDefinition]="model.flowDefinition"
                                                              (outputModelChangeEvent)="outboundApiChangeHandler($event)"
                                    ></flow-object-outbound-api>

                                    <flow-object-finish-content *ngIf="flowObjectInstance.flowObjectDefinition.typeId == FlowObjectType.FinishContent"
                                                                [inputModel]="flowObjectInstance"
                                                                [inputFlowInstance]="model"
                                                                [inputFlowDefinition]="model.flowDefinition"
                                                                (outputSubmitEvent)="executeTask($event)"
                                    ></flow-object-finish-content>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>

                <!-- Botão para retorno ao Conecta ES -->
                <a class="return-to-conecta" *ngIf="isFromConecta && isBootstrapFinished"
                   href="//conecta.{{conectaEnvironmentSegment}}es.gov.br/servico/{{model.flowDefinition.catalogServiceId}}"
                >
                    <div class="btn btn-outline-primary mt-4">
                        <i class="fas fa-arrow-left mr-2"></i>
                        <span>Voltar para o serviço</span>
                    </div>
                </a>
            </div>
        </ng-template>
    </div>
</div>
