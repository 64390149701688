import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    AfterViewInit
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectType
} from '../../../models/flow-object.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewDialogComponent } from '../../pdf-preview-dialog/pdf-preview-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Papel } from '../../../models/edocs.model';
import { Utils } from '../../../shared/utils';
import { FlowDefinition, FlowInstance } from '../../../models/flow.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { InputDataTaskApprove, OutputDataTaskApprove } from '../../../models/input-output-data.model';
import { OrganogramaService } from '../../../services/organograma.service';

@Component({
    selector: 'flow-object-approve',
    templateUrl: './flow-object-approve.component.html',
    styleUrls: ['./flow-object-approve.component.scss']
})
export class FlowObjectApproveComponent implements OnInit, AfterViewInit {
    // #region [ViewChild]
    @ViewChild('papelRef') papelRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [const]
    DEFAULT_EMPTY_VALUE: string = '(N/D)' as const;
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;
    // #endregion

    // #region [properties]
    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskApprove;
    outputData: OutputDataTaskApprove;
    papeisSelector: Papel[] = [];
    recipient: string = '';
    forwardingProtocol: string = this.DEFAULT_EMPTY_VALUE;
    processProtocol: string = this.DEFAULT_EMPTY_VALUE;
    previousDocuments: any[] = [];
    currentDocuments: any[] = [];
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;
    // #endregion

    // #region [getters]
    get hasPendingTask(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.Started;
    }
    get isTaskPublicAgentActor(): boolean {
        return this.authService?.user?.isPublicAgent
            && this.model?.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id);
    }
    get isReadOnly(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.NotStarted
        ].includes(this.model?.stateId);
    }
    get isTaskCancelled(): boolean {
        return [
            FlowObjectInstanceState.AutomaticallyCancelled,
            FlowObjectInstanceState.ManuallyCancelled
        ].includes(this.model?.stateId);
    }
    get isLackingMessage(): boolean {
        return Utils.isNullOrEmpty(this.inputData?.message) || this.inputData.message.trim().length < 5;
    }
    get dateString(): string {
        return this.model == null ? '' : new Date(this.model.updateDate).toLocaleString();
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowInstance: FlowInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectInstance>();
    // #endregion

    constructor(
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private authService: AuthService,
        private eDocsService: EDocsService,
        private organogramaService: OrganogramaService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);
        this.inputData = Object.assign(new InputDataTaskApprove(), JSON.parse(this.model.inputData));
        this.outputData = this.model.outputData == null
            ? null
            : Object.assign(new OutputDataTaskApprove(), JSON.parse(this.model.outputData));

        const response_Encaminhamento = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_Encaminhamento.isSuccess) {
            this.toastr.error(response_Encaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamento = response_Encaminhamento.data;
        this.forwardingProtocol = encaminhamento.protocolo;

        // #region [documentos capturados anteriormente à Tarefa corrente]
        for (let item of encaminhamento.documentosEncaminhamento.filter(x => !x.controle)) {
            if (!Utils.isNullOrEmpty(this.outputData) && this.outputData.eDocsData.forwardingId == item.documento.encaminhamentoId) {
                break;
            }

            if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                this.previousDocuments.push({
                    id: item.documento.id,
                    registro: item.documento.registro,
                    filename: `${item.documento.nome}.${item.documento.extensao}`
                });
            }
        }
        // #endregion

        // #region [documentos capturados na Tarefa corrente]
        if (!Utils.isNullOrEmpty(this.outputData)) {
            let filtered = encaminhamento.documentosEncaminhamento.filter(x =>
                x.documento.encaminhamentoId == this.outputData.eDocsData.forwardingId
                && !x.controle
            );

            for (let item of filtered) {
                if (!this.previousDocuments.some(x => x.registro == item.documento.registro)) {
                    this.currentDocuments.push({
                        id: item.documento.id,
                        registro: item.documento.registro,
                        filename: `${item.documento.nome}.${item.documento.extensao}`
                    });
                }
            }
        }
        // #endregion

        // #region [determinação do destinatário]
        const response_LocationInfo = await this.organogramaService.getLocationInfo(this.inputData.eDocsData.currentForwardingLocationId);

        if (!response_LocationInfo.isSuccess) {
            this.toastr.error(response_LocationInfo.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamentoDestino = response_LocationInfo.data;
        this.recipient = encaminhamentoDestino.nome
            + (!Utils.isStringEqual(encaminhamentoDestino.nome, encaminhamentoDestino.sigla) ? ` - ${encaminhamentoDestino.sigla}` : '')
            + ` - ${encaminhamentoDestino.paiSigla}`;
        // #endregion

        const response_UsuarioPapeisEncaminhamento = await this.eDocsService.getUsuarioPapeisEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response_UsuarioPapeisEncaminhamento.isSuccess) {
            this.toastr.error(response_UsuarioPapeisEncaminhamento.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        this.papeisSelector = response_UsuarioPapeisEncaminhamento.data;

        // força o usuário a selecionar um papel manualmente
        setTimeout(() => this.inputData.eDocsData.signerId = null, 50);

        if (this.papeisSelector.length == 0) {
            this.inputData.eDocsData.signerId = this.authService.user.id;
        }

        if (!Utils.isNullOrEmpty(this.inputData.eDocsData.processId)) {
            const response_Processo = await this.eDocsService.getProcesso(this.inputData.eDocsData.processId);

            if (!response_Processo.isSuccess) {
                this.toastr.error(response_Processo.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.processProtocol = response_Processo.data.protocolo;
        }

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getParsedMessageHtml(
            this.cookieService,
            this.flowObjectDefinition.publicMessageHtml,
            this.forwardingProtocol,
            this.processProtocol
        ));
    }

    ngAfterViewInit() {
        if (this.hasPendingTask && Utils.isNullOrEmpty(this.inputData.eDocsData.signerId)) {
            this.inputData.eDocsData.signerId = this.authService.user.papeis.find(x => x.prioritario).guid;
        }
    }

    // ======================
    // public methods
    // ======================

    async openDocumentModal(id: string) {
        this.spinner.show('flowInstance');

        const response = await this.eDocsService.getDocumentoDownloadUrl(id);

        this.spinner.hide('flowInstance');

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        setTimeout(() => {
            this.dialog.open(PdfPreviewDialogComponent, {
                data: {
                    content: response.data
                }
            });
        }, 200);
    }

    submitApproval(hasApproved: boolean) {
        if (this.isLackingMessage) {
            this.toastr.warning(Enums.Messages.MessageMinlengthError, Enums.Messages.Warning, Utils.getToastrErrorOptions());
            return;
        }

        this.inputData.hasApproved = hasApproved;
        this.model.inputData = JSON.stringify(this.inputData);

        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================
}
