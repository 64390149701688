import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    FlowObjectDefinition,
    FlowObjectInstance,
    FlowObjectInstanceState,
    FlowObjectType
} from '../../../models/flow-object.model';
import {
    InputDataTaskDispatchProcess,
    InputDataTaskForm,
    OutputDataTaskDispatchProcess
} from '../../../models/input-output-data.model';
import { ToastrService } from 'ngx-toastr';
import { Enums } from '../../../shared/enums';
import { AuthService } from '../../../services/auth.service';
import { EDocsService } from '../../../services/edocs.service';
import { Papel, Processo } from '../../../models/edocs.model';
import { Utils } from '../../../shared/utils';
import { FlowDefinition, FlowInstance } from '../../../models/flow.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ConfigSchema } from '../../../models/config-schema.model';

@Component({
    selector: 'flow-object-dispatch-process',
    templateUrl: './flow-object-dispatch-process.component.html',
    styleUrls: ['./flow-object-dispatch-process.component.scss']
})
export class FlowObjectDispatchProcessComponent implements OnInit {
    // #region [ViewChild]
    @ViewChild('papelRef') papelRef: ElementRef;
    // #endregion

    // #region [Type properties]
    FlowObjectInstanceState: typeof FlowObjectInstanceState = FlowObjectInstanceState;
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [const]
    DEFAULT_LOADING_TEXT: string = 'Carregando...' as const;
    // #endregion

    // #region [properties]
    model: FlowObjectInstance;
    flowObjectDefinition: FlowObjectDefinition = null;
    inputData: InputDataTaskDispatchProcess = null;
    outputData: OutputDataTaskDispatchProcess = null;
    configSchema: ConfigSchema = null;
    papeisSelector: Papel[] = [];
    process: Processo = null;
    dispatcher: string = '';
    custodyHolder: string = '(N/D)';
    forwardingProtocol: string = '(N/D)';
    processProtocol: string = '(N/D)';
    publicMessageHtml: SafeHtml = this.DEFAULT_LOADING_TEXT;
    // #endregion

    // #region [getters]
    get hasPendingTask(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.Started;
    }
    get isTaskPublicAgentActor(): boolean {
        return this.authService?.user?.isPublicAgent
            && this.model?.flowObjectInstanceActors.some(x => x.actorId == this.authService.user.id);
    }
    get isReadOnly(): boolean {
        return [
            FlowObjectInstanceState.Finished,
            FlowObjectInstanceState.NotStarted
        ].includes(this.model?.stateId);
    }
    get isTaskCancelled(): boolean {
        return this.isTaskAutomaticallyCancelled || this.isTaskManuallyCancelled;
    }
    get isTaskAutomaticallyCancelled(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.AutomaticallyCancelled;
    }
    get isTaskManuallyCancelled(): boolean {
        return this.model?.stateId == FlowObjectInstanceState.ManuallyCancelled;
    }
    get dateString(): string {
        return this.model == null ? '' : new Date(this.model.updateDate).toLocaleString();
    }
    get dispatchSummary(): string {
        return this.configSchema?.taskDispatchProcess?.dispatchSummary?.replaceAll('\n', '<br>');
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectInstance;
    @Input() inputFlowInstance: FlowInstance;
    @Input() inputFlowDefinition: FlowDefinition;
    @Output() outputSubmitEvent = new EventEmitter<FlowObjectInstance>();
    // #endregion

    constructor(
        private sanitizer: DomSanitizer,
        private toastr: ToastrService,
        private cookieService: CookieService,
        private authService: AuthService,
        private eDocsService: EDocsService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.flowObjectDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.id == this.model.flowObjectDefinitionId);
        this.inputData = !Utils.isNullOrEmpty(this.model.inputData)
            ? JSON.parse(this.model.inputData) as InputDataTaskDispatchProcess
            : new InputDataTaskDispatchProcess()
        this.outputData = !Utils.isNullOrEmpty(this.model.outputData)
            ? JSON.parse(this.model.outputData) as OutputDataTaskDispatchProcess
            : new OutputDataTaskDispatchProcess();
        this.configSchema = JSON.parse(this.model.flowObjectDefinition.configSchema) as ConfigSchema;

        if (this.isTaskPublicAgentActor) {
            this.papeisSelector = this.authService.user.papeis;

            // força o usuário a selecionar um papel manualmente
            setTimeout(() => this.inputData.eDocsData.signerId = null, 50);
        }

        if (this.papeisSelector.length == 0) {
            this.inputData.eDocsData.signerId = this.authService.user.id;
        }

        // #region [Encaminhamento]
        const response = await this.eDocsService.getEncaminhamento(this.inputData.eDocsData.forwardingId);

        if (!response.isSuccess) {
            this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
            return;
        }

        const encaminhamento = response.data;
        this.forwardingProtocol = encaminhamento.protocolo;
        // #endregion

        // #region [Processo]
        if (!Utils.isNullOrEmpty(this.inputData.eDocsData.processId)) {
            const response = await this.eDocsService.getProcesso(this.inputData.eDocsData.processId);

            if (!response.isSuccess) {
                this.toastr.error(response.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            this.process = response.data;
            this.processProtocol = this.process.protocolo;

            // resolve o nome do detentor atual da custódia do Processo
            const response_LocalCustodia = await this.eDocsService.getProcessoLocalCustodia(this.inputData.eDocsData.processId);

            if (!response_LocalCustodia.isSuccess) {
                this.toastr.error(response_LocalCustodia.message.description, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            const localCustodia = response_LocalCustodia.data;
            this.custodyHolder = localCustodia.nome;
        }
        // #endregion

        // #region [Resumo do Processo]
        this.resolveDispatchSummary();

        this.publicMessageHtml = this.sanitizer.bypassSecurityTrustHtml(Utils.getParsedMessageHtml(
            this.cookieService,
            this.flowObjectDefinition.publicMessageHtml,
            this.forwardingProtocol,
            this.processProtocol
        ));
        // #endregion
    }

    // ======================
    // public methods
    // ======================

    goToProcess() {
        const environment = atob(this.cookieService.get('prodest-eflow-env')).toLowerCase();
        const resolvedSubdomain = `${['prd', 'hom'].includes(environment) ? '' : 'treinamento.'}e-docs.${environment == 'hom' ? 'hom.' : ''}`;
        window.open(`https://${resolvedSubdomain}es.gov.br/p/${this.processProtocol}`, '_blank');
    }

    submit() {
        this.model.inputData = JSON.stringify(this.inputData);
        this.outputSubmitEvent.emit(this.model);
    }

    // ======================
    // private methods
    // ======================

    private getFormData(): any {
        let formTaskDefinition = this.inputFlowDefinition.flowObjectDefinitions.find(x => x.typeId == FlowObjectType.StartForm);
        if (formTaskDefinition == null) return null;

        let formTaskInstance = this.inputFlowInstance.flowObjectInstances.find(x => x.flowObjectDefinitionId == formTaskDefinition.id);
        let formData = JSON.parse(formTaskInstance.inputData) as InputDataTaskForm;

        return formData.data;
    }

    private resolveDispatchSummary() {
        let formData = this.getFormData();
        if (formData == null) return;

        let tags = this.configSchema.taskDispatchProcess.dispatchSummary.match(/\{\|([\w]+)\|\}/g);
        if (tags?.length > 0) {
            let formFields = tags.map(x => x.replace(/[^\w]/g, ''));

            for (let i = 0; i < tags.length; i++) {
                this.configSchema.taskDispatchProcess.dispatchSummary = this.configSchema.taskDispatchProcess.dispatchSummary.replaceAll(
                    tags[i],
                    formData[formFields[i]] || '(N/D)'
                );
            }
        }
    }
}
