<div class="nav-item dropdown p-0">
    <a class="nav-link" matTooltip="Menu Principal" #headerButtonRef>
        <i class="fas fa-bars"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-left" #dropdownMenuRef>
        <a class="entry-row pl-2 py-2"
           matTooltip="Página Inicial"
           [routerLink]="[Enums.PagesPaths.Home]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-home"></i>
                <span>Início</span>
            </div>
        </a>

        <a class="entry-row pl-2 py-2"
           matTooltip="Escolha e inicie um dos Fluxos disponíveis para o seu usuário"
           [routerLink]="[Enums.PagesPaths.FlowDefinitionList]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-play-circle"></i>
                <span>Iniciar um Fluxo</span>
            </div>
        </a>

        <a class="entry-row pl-2 py-2"
           matTooltip="Confira os Fluxos já iniciados nos quais haja participação do seu usuário"
           [routerLink]="[Enums.PagesPaths.FlowInstanceList]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-folder-open"></i>
                <span>Meus Fluxos</span>
            </div>
        </a>

        <a class="entry-row pl-2 py-2"
           [routerLink]="[Enums.PagesPaths.TermosUso]"
           (click)="toggleMenu()"
        >
            <div class="btn btn-default btn-flat p-0">
                <i class="fas fa-file-signature"></i>
                <span>Termos e Condições de Uso</span>
            </div>
        </a>

        <ng-container *ngIf="authService.user?.perfis.includes(Enums.Profiles.Owner)">
            <a class="entry-row pl-2 py-2"
               matTooltip="Acesse a página de testes de performance do E-Flow"
               [routerLink]="[Enums.PagesPaths.Tests]"
               (click)="toggleMenu()"
            >
                <div class="btn btn-default btn-flat p-0">
                    <i class="fas fa-tasks"></i>
                    <span>Testes</span>
                </div>
            </a>
        </ng-container>
    </div>
</div>
