import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'termos-uso',
    templateUrl: './termos-uso.component.html',
    styleUrls: ['./termos-uso.component.scss']
})
export class TermosUsoComponent implements OnInit {
    safeUrl: SafeResourceUrl;

    constructor(
        private cookieService: CookieService,
        private sanitizer: DomSanitizer
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    ngOnInit() {
        let url = atob(this.cookieService.get('prodest-eflow-tcu')).toLowerCase();
        let sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
    }

    // ======================
    // public methods
    // ======================

    // ======================
    // private methods
    // ======================
}
